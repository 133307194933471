import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'
import ProjectThumbnail from './project-thumbnail'

export default function HomepageFeaturedProjectsSection({}) {
  const data = useStaticQuery(graphql`
    {
      prismicHomePage {
        data {
          featured_projects_heading {
            text
          }
          featured_projects_intro_text {
            html
          }
        }
      }
      allPrismicProject {
        edges {
          node {
            data {
              enabled
              is_completed
              project_date
              project_name {
                text
              }
              project_description {
                html
              }
              location {
                text
              }
              primary_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              featured_project
            }
            prismicId
            uid
          }
        }
      }
    }
  `)

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const prismicData = {
    heading: data.prismicHomePage.data.featured_projects_heading.text,
    introText: data.prismicHomePage.data.featured_projects_intro_text.html,
    projects: data.allPrismicProject.edges.map(edge => {
      let dateObjToUse
      if (edge.node.data.project_date) {
        dateObjToUse = new Date(edge.node.data.project_date)
      } else {
        dateObjToUse = currentDate
      }
      return {
        date: dateObjToUse,
        description: edge.node.data.project_description.html,
        location: edge.node.data.location.text,
        enabled: edge.node.data.enabled,
        isCompleted: edge.node.data.is_completed,
        featuredProject: edge.node.data.featured_project,
        name: edge.node.data.project_name.text,
        primaryImageAlt: edge.node.data.primary_image.alt,
        primaryImageHeight: edge.node.data.primary_image.dimensions?.height,
        primaryImageUrl: edge.node.data.primary_image.url,
        primaryImageWidth: edge.node.data.primary_image.dimensions?.width,
        prismicId: edge.node.prismicId,
        slug: edge.node.uid,
        year: edge.node.data.project_date?.split('-')[0] || currentYear,
      }
    }),
  }

  prismicData.projects = prismicData.projects.filter(item => item.enabled)
  prismicData.projects.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date)
  })
  const featuredProjects = prismicData.projects.filter(project => project.featuredProject)

  return (
    <StyledComponent>
      <Container type="wide">
        <div className="row">
          <hr />
          <Fade>
            <h2 className="heading">{prismicData.heading}</h2>
          </Fade>
          {prismicData.introText && (
            <Fade>
              <div className="intro-text" dangerouslySetInnerHTML={{__html: prismicData.introText}} />
            </Fade>
          )}
          {featuredProjects.length > 0 && (
            <ol className="projects-list">
              {featuredProjects.map(project => (
                <ProjectThumbnail
                  key={project.prismicId}
                  name={project.name}
                  location={project.location}
                  isCompleted={project.isCompleted}
                  primaryImageAlt={project.primaryImageAlt}
                  primaryImageHeight={project.primaryImageHeight}
                  primaryImageUrl={project.primaryImageUrl}
                  primaryImageWidth={project.primaryImageWidth}
                  slug={project.slug}
                />
              ))}
            </ol>
          )}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 6rem;

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  hr {
    margin-bottom: 4rem;
  }

  .heading {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 2rem 0;
    padding: 0 0 0 0;
    text-transform: uppercase;
    text-align: center;
  }

  .intro-text {
    margin: 2rem auto;
    max-width: 58rem;
    text-align: center;
  }

  .projects-list {
    list-style: none;
    padding: 0;
    margin: 0 0 4rem 0;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 4rem;
    grid-template-columns: 1fr;

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 800px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 950px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    > * {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
`
